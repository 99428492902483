import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

import useAuth from '@/hooks/useAuth';
import { fCurrency } from '@/utils/formatNumber';
import { formatDate } from '@/utils/formatDate';
import { getAdsOrders } from '@/redux/slices/adsOrders';
import Spinner from '@/components/Spinner';
import { ErrorLoadingData } from '@/components/errors';

const Title = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25em',
  marginBottom: 16,
  strong: {
    color: '#234796',
  },

  '.MuiButtonBase-root': {
    marginLeft: 'auto',
  },
});

const Loading = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 600,
  height: 200,
});

const Content = styled(Paper)({
  border: '1px solid #E1E5EA',
  boxShadow:
    '0 1px 3px rgba(16, 25, 40, 0.05), 0 1px 2px rgba(16, 25, 40, 0.03)',
  borderRadius: 10,
  overflow: 'hidden',

  '.MuiTableContainer-root': {
    maxHeight: 'calc(100vh - 180px)',
  },

  '.MuiTableBody-root .MuiTableRow-root': {
    '&:nth-of-type(odd)': {
      background: '#FAFAFB',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    a: {
      color: '#1461cc',
      fontWeight: 600,
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },

  '.MuiTableCell-root': {
    borderBottom: '1px solid #EAECF0',
  },

  '.MuiTableCell-head': {
    padding: '10px 24px',
    fontSize: 12,
    fontWeight: 700,
    color: '#1c1e21',
    backgroundColor: 'transparent',
  },

  '.MuiTableCell-body': {
    padding: '11px 24px',
    fontSize: 12,
    color: '#1c1e21',
  },
});

const AdsOrdersDialog = ({ open, params, onClose, metaOrders }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const storeId = user?.store_id;
  const { isLoading, data, error } = useSelector((state) => state.adsOrders);

  const total = useMemo(
    () => data.reduce((s, item) => s + Number(item.total_price), 0),
    [data]
  );

  useEffect(() => {
    if (open) {
      dispatch(
        getAdsOrders({
          store_id: storeId,
          ...params,
        })
      );
    }
  }, [open, storeId, params, dispatch]);

  const isMetaOrders = metaOrders?.purchases > 0;

  return (
    <Dialog maxWidth="md" open={open} onClose={() => onClose()}>
      <DialogContent>
        {isLoading ? (
          <Loading>
            <Spinner />
          </Loading>
        ) : error ? (
          <>
            <Title variant="subtitle1">
              Orders
              <IconButton onClick={() => onClose()}>
                <CloseIcon />
              </IconButton>
            </Title>
            <Loading>
              <ErrorLoadingData />
            </Loading>
          </>
        ) : (
          <>
            <Title variant="subtitle1">
              <strong>{data.length + (metaOrders?.purchases || 0)}</strong>
              Orders with Conversion Value of
              <strong>{fCurrency(total + (metaOrders?.sales || 0))}</strong>
              <IconButton onClick={() => onClose()}>
                <CloseIcon />
              </IconButton>
            </Title>
            {isMetaOrders && (
              <Typography
                variant="body1"
                sx={{
                  mb: 2,
                  marginTop: '10px',
                  fontSize: '0.8rem',
                  fontWeight: 600,
                  paddingBottom: '10px',
                }}
              >
                ({data.length} Orders from Website, {metaOrders.purchases} Order
                {metaOrders.purchases > 1 ? 's' : ''} from Meta Shop)
              </Typography>
            )}
            <Content>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Order</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell>Cust. Type</TableCell>
                      <TableCell>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isMetaOrders && (
                      <TableRow key="metaOrders">
                        <TableCell>Meta Shop Orders</TableCell>
                        <TableCell>--</TableCell>
                        <TableCell>
                          {metaOrders.purchases} Order
                          {metaOrders.purchases > 1 ? 's' : ''}
                        </TableCell>
                        <TableCell>--</TableCell>
                        <TableCell>{fCurrency(metaOrders.sales)}</TableCell>
                      </TableRow>
                    )}
                    {data.map((item) => (
                      <TableRow key={item.order_id}>
                        <TableCell>
                          <a
                            href={`/dashboard/orders/${item.order_id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.order_name}
                          </a>
                        </TableCell>
                        <TableCell>
                          {formatDate(item.order_created_at)}
                        </TableCell>
                        <TableCell>
                          {item.customer_firstname} {item.customer_lastname}
                        </TableCell>
                        <TableCell sx={{ textTransform: 'capitalize' }}>
                          {item.customer_type}
                        </TableCell>
                        <TableCell>{fCurrency(item.total_price)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Content>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AdsOrdersDialog;
